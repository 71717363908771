import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VModal from "vue-js-modal";
import preview from "vue-photo-preview";
import Vant, { Toast } from "vant";
import "vue-photo-preview/dist/skin.css";
import "vant/lib/index.css";

import App from "./App.vue";
import router from "./router.js";
import VueCryptojs from "vue-cryptojs";
// ali oss
// let OSS = require('ali-oss');

import i18n from './lang'

Vue.use(VueAxios, axios);
Vue.use(Vant);
Vue.config.productionTip = false;
let options = {
  fullscreenEl: false,
};
Vue.use(preview, options);
Vue.use(preview);
Vue.use(VueCryptojs);
Vue.use(VModal, { clickToClose: true });

Vue.prototype.$Toast = Toast;
// 调试模式
Vue.config.devtools = process.env.VUE_APP_DEV === "true";
// oss
// Vue.prototype.$oss = OSS;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
