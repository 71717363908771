import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhLocale from './zh';
import ugLocale from './ug';

Vue.use(VueI18n)

const messages = {
  zh: zhLocale,
  ug: ugLocale
};

export default new VueI18n({
  locale: localStorage.getItem('ZHJD_lang') || 'zh',
  messages
})